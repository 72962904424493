<template>
  <section id="workflow-users-list">
    <div class="inner-navbar">
      <el-row type="flex" align="middle" :class="getIsMobile ? 'mt-2' : ''">
        <el-col :span="12">
          <div class="icon-block d-flex align-center">
             <div
                class="icon"
                v-if="
                  (!this.$route.query.routeType|| this.$route.query.routeType == 'FB') && !this.isApplicationUserSide
                "
              >
           <el-button icon="el-icon-arrow-left" @click="backToWorkFlows" circle></el-button>
            </div>

            <div class="icon-text pl-1">
              <h3 class="table-head-text">{{ getFlowName() }}</h3>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="right-block float-right">
            <el-button class="right-block float-right add-buttons mt-0 button-uppercase" @click="openStartFormbuilder">
              <i class="el-icon-circle-plus-outline pr-1"></i>
              {{
                getFormBuilder &&
                getFormBuilder.customization &&
                getFormBuilder.customization.add_button_label
                ? getFormBuilder.customization.add_button_label
                : "Add"
              }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <hr v-if="workFlowPublicURL" />
      <el-row>
        <el-col :span="24" v-if="workFlowPublicURL">
          <div>
            <el-alert :closable="false" effect="dark">
              <template slot="title">You can share form builder with public URL</template>

              <template>
                <span>{{ workFlowPublicURL }}</span>
                <el-button icon="el-icon-copy-document" @click="copyURL(workFlowPublicURL)" size="mini"
                  class="ml-1"></el-button>

                <!-- <formBuilderSharingSettings
                  :workFlowPublicURL="workFlowPublicURL"
                  :workflow_details_id="formbuilderDetails._id"
                  :formbuilderDetails="formbuilderDetails"
                ></formBuilderSharingSettings>-->
              </template>
            </el-alert>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="right-block float-right" v-if="getFormBuilder &&
            getFormBuilder.customization &&
            getFormBuilder.customization.show_detailed_link
            ">
            <!-- <template v-if="getFormBuilder && getFormBuilder.form_builders_owner == 'ENTITY'">
              <router-link :to="getWorkflowTableAllUsersRoute"
                >All Users</router-link
              >|
              <router-link :to="getWorkflowTableBatchesRoute"
                >Form builder Batches</router-link
              >|
            </template> -->

            <router-link :to="getFormbuilderTableDataRoute">Detailed View</router-link>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="12" v-if="getFormBuilder &&
        getFormBuilder.settings &&
        getFormBuilder.settings.limit_total_submissions
        ">
        <el-col :span="8">
          <el-card shadow="always">
            Total Allowed -
            {{ getFormBuilder.settings.no_total_submissions_limited }}
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="always">Total Submited - {{ total }}</el-card>
        </el-col>
      </el-row>

      <el-row :gutter="12" v-if="getFormBuilder &&
        getFormBuilder.settings &&
        getFormBuilder.settings.limit_total_submissions &&
        getFormBuilder.settings.no_total_submissions_limited == total
        ">
        <el-col :span="24">
          <el-alert title="Form builder Completed" type="success" description="Form builder reach our maximum submissions"
            show-icon closable></el-alert>
        </el-col>
      </el-row>

      <el-row :gutter="12" v-if="selectedUserId">
        <el-col :span="6">
          <el-select v-model="selectedUserId" @change="updateUserQuery">
            <el-option v-for="(user, index) of getFormbuilderUniqueUsersData.data" :key="index"
              :label="getUserEmail(user)" :value="getUserId(user)"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div class="mt-1">
      <el-button size="large" class="el-dropdown-link px-2 mb-10" plain 
           v-if="this.selectedRowsIds.length > 0" @click="deleteChecked">
            Delete
           <i class="el-icon-delete el-icon--right"></i>
        </el-button>
      <div class="vue-data-table-default">
        <data-tables-server :data="data" :total="total" :current-page.sync="currentPage" :page-size="pageSize"
          :pagination-props="paginationProps" @query-change="loadData" v-loading="loading" v-if="!getIsMobile"
           @selection-change="handleSelectionChange">
            <el-table-column
                type="selection"
                width="55"
              >
            </el-table-column>
          <el-table-column width="50" fixed="left">
            <template>
              <div>
                <img src="@/assets/img/avatar-2.svg" alt="avatar" width="30" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="User" min-width="210">
            <template slot-scope="scope">
              <div class="d-flex" v-if="!isAnonymousForm()">
                <div class="pl-15" v-if="getUserEmail(scope.row)">{{ getUserEmail(scope.row) }}</div>
                <div class="pl-15" v-else>{{ scope.row.ip_address }}</div>
              </div>
              <div v-else>
                <div class="pl-15" v-if="getUserEmail(scope.row)">{{ getUserEmail(scope.row) }}</div>
                <div class="pl-15" v-else>{{ scope.row.ip_address }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="action" min-width="120">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  Actions
                  <i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <a @click="onApprove(scope.row)" v-if="checkIsApproverUser(scope.row)">
                    <el-dropdown-item icon="el-icon-finished" command="view">Approve</el-dropdown-item>
                  </a>
                  <a @click="showRejectConfirm(scope.row)" v-if="checkIsApproverUser(scope.row)">
                    <el-dropdown-item icon="el-icon-circle-close" command="view">Reject</el-dropdown-item>
                  </a>
                  <a @click="viewFormbuilderData(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-view" command="view">View</el-dropdown-item>
                  </a>
                  <a @click="editWorkUserData(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-edit-outline" command="edit">Edit</el-dropdown-item>
                  </a>
                  <a @click="onDelete(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-delete" command="delete">Delete</el-dropdown-item>
                  </a>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>

          <el-table-column v-if="hasIntervals" label="Submitted For" min-width="150">
            <template slot-scope="scope">
              <div>
                <span class="black-coral sm-80" v-if="scope.row.time_interval">
                  {{
                    scope.row.time_interval
                    | moment(fequencyDateMap[intervalType])
                  }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column v-if="isApprovalFormbuilder" label="Approval Status" min-width="300">
            <template slot-scope="scope">
              <div class="success" v-if="scope.row.approval_status == 'APPROVED'">
                <el-popover placement="top-start" title="Approval details:" width="300" trigger="hover">
                  <div v-if="scope.row.priority_based">
                    <!-- In priority based approvals, only main approver details should be displayed -->
                    <span v-if="getApprovedUser(scope.row) &&
                      getApprovedUser(scope.row).approved_by
                      ">Approved by: {{ getApprovedUser(scope.row).approved_by }}
                    </span>
                    <br />
                    <span v-if="getApprovedUser(scope.row) &&
                      getApprovedUser(scope.row).approved_at
                      ">Approved on:
                      {{
                        convertToLocalDateTime(getApprovedUser(scope.row).approved_at)
                        | globalDateTimeFormat
                      }}</span>
                  </div>
                  <div v-else>
                    <!-- In non-priority based(all users need to approve) approvals, all approvers details should be displayed -->
                    <ul>
                      <li v-for="approver in getApprovedUser(scope.row)" :key="approver._id" class="list-style">
                        <div v-if="approver.approved_by && approver.approved_at">
                           <i class="el-icon-user-solid icon-style"></i>
                           Approved by:<span class="approver-text">{{ ' ' + approver.approved_by + ',' }}</span><br />
                           <i class="el-icon-time icon-style"></i>
                           Approved at:<span class="approver-text">{{ ' ' + convertToLocalDateTime(approver.approved_at) | globalDateTimeFormat}}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div slot="reference">
                    <i class="el-icon-success"></i>
                    <span class="d-inline-block pl-10">
                      {{
                        scope.row.approval_status == "APPROVED"
                        ? "Approved"
                        : ""
                      }}
                    </span>
                  </div>
                </el-popover>
              </div>
              <div class="error" v-else-if="scope.row.approval_status == 'REJECTED'">
                <el-popover placement="top-start" title="Rejection details:" width="300" trigger="hover">
                  <div>
                    <span v-if="getRejectedUser(scope.row) &&
                      getRejectedUser(scope.row).rejected_by
                      ">Rejected by: {{ getRejectedUser(scope.row).rejected_by }}
                    </span>
                    <br />
                    <span v-if="getRejectedUser(scope.row) &&
                      getRejectedUser(scope.row).rejected_at
                      ">Rejected on:
                      {{
                        convertToLocalDateTime(getRejectedUser(scope.row).rejected_at)
                        | globalDateTimeFormat
                      }}</span>
                    <br />
                    <span v-if="getRejectedUser(scope.row) &&
                        getRejectedUser(scope.row).rejected_reason
                        " style="word-break: break-word">
                      Reason:
                      {{ getRejectedUser(scope.row).rejected_reason }}
                    </span>
                  </div>
                  <div slot="reference">
                    <i class="el-icon-error"></i>
                    <span class="d-inline-block pl-10">
                      {{
                        scope.row.approval_status == "REJECTED"
                        ? "Rejected"
                        : ""
                      }}
                    </span>
                  </div>
                </el-popover>
              </div>
              <div :class="getReviewerData(scope.row) && getReviewerData(scope.row).status == 'REJECTED' ? 'error' : 'success'" v-else-if="checkReviewer(scope.row)">
                <el-popover placement="top-start" :title="getReviewerData(scope.row).title" width="300" trigger="hover">
                  <div>
                    <span v-if="getReviewerData(scope.row).label1">{{ 
                      getReviewerData(scope.row).label1 }}
                    </span>
                    <br />
                    <span v-if="getReviewerData(scope.row).date">On:
                      {{ getReviewerData(scope.row).date | globalDateTimeFormat }}</span>
                    <br />
                    <span v-if="getReviewerData(scope.row).reason"
                      style="word-break: break-word">
                      Reason:
                      {{ getReviewerData(scope.row).reason }}
                    </span>
                  </div>
                  <div slot="reference">
                    <i :class="getReviewerData(scope.row).status == 'REJECTED' ? 'el-icon-error' : 'el-icon-success'"></i>
                    <span class="d-inline-block" style="word-break: break-word">
                      {{
                        getReviewerData(scope.row).status +' by '+ getReviewerData(scope.row).user_name  
                      }}
                    </span>
                  </div>
                </el-popover>
              </div>
              <div class="warning d-flex" v-else-if="scope.row.approval_users && scope.row.approval_users.length
                  ">
                <!-- <i class="el-icon-time"></i> -->
                <span class="d-inline-block pl-10">Pending</span>
                 <formbuilderLogs class="warning" :currentFormbuilderData="scope.row" :allEntitiesData="allEntitiesData" :allCompanyUserTypesData="allCompanyUserTypesData"></formbuilderLogs>
              </div>
              <div class="warning" v-else>
                <span class="d-inline-block pl-10"> Not approval data </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Sent Status" min-width="150">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.sent_at" class="d-block yale-blue sm-80 line-height-1">Sent On</span>
                <span class="black-coral sm-80" v-if="scope.row.sent_at">{{
                  scope.row.sent_at | moment("MM-DD-YYYY HH:mm")
                }}</span>

                <span v-if="!scope.row.sent_at" class="d-block yale-blue sm-80 line-height-1">Not Send</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Completion Status" width="250" fixed="right">
            <template slot-scope="scope">
              <div class="warning">
                <i class="el-icon-refresh" v-if="scope.row.formbuilder_data_status == 'PENDING' ||
                  scope.row.formbuilder_data_status == 'SENT'
                  "></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "PENDING" ||
                    scope.row.formbuilder_data_status == "SENT"
                    ? "Pending"
                    : ""
                  }}
                </span>
              </div>

              <div class="success">
                <i class="el-icon-success" v-if="scope.row.formbuilder_data_status == 'COMPLETED'"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "COMPLETED"
                    ? "Completed"
                    : ""
                  }}
                </span>
              </div>
            </template>
          </el-table-column>
        </data-tables-server>
        <data-tables-server :data="data" :total="total" :current-page.sync="currentPage" :page-size="pageSize"
          :pagination-props="paginationProps" @query-change="loadData" v-loading="loading" v-else-if="getIsMobile">
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="expand-column-item" v-if="hasIntervals">
                <div class="expand-column-item-label">Submitted For</div>
                <div class="expand-column-item-content">
                  {{
                    scope.row.time_interval
                    | moment(fequencyDateMap[intervalType])
                  }}
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Sent Status</div>
                <div class="expand-column-item-content">
                  {{ scope.row.sent_at | moment("MM-DD-YYYY HH:mm") }}
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Completion Status</div>
                <div class="expand-column-item-content">
                  <div class="warning">
                    <i class="el-icon-refresh" v-if="scope.row.formbuilder_data_status == 'PENDING' ||
                      scope.row.formbuilder_data_status == 'SENT'
                      "></i>
                    <span class="d-inline-block pl-10">
                      {{
                        scope.row.formbuilder_data_status == "PENDING" ||
                        scope.row.formbuilder_data_status == "SENT"
                        ? "Pending"
                        : ""
                      }}
                    </span>
                  </div>

                  <div class="success">
                    <i class="el-icon-success" v-if="scope.row.formbuilder_data_status == 'COMPLETED'"></i>
                    <span class="d-inline-block pl-10">
                      {{
                        scope.row.formbuilder_data_status == "COMPLETED"
                        ? "Completed"
                        : ""
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="50" fixed="left">
            <template>
              <div>
                <img src="@/assets/img/avatar-2.svg" alt="avatar" width="30" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="User" min-width="300">
            <template slot-scope="scope">
              <div class="d-flex" v-if="!isAnonymousForm()">
                <div class="pl-15">{{ getUserEmail(scope.row) }}</div>
              </div>
              <div v-else>
                <div class="pl-15">{{ scope.row.ip_address }}</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="action" min-width="100px">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  Actions
                  <i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <a @click="viewFormbuilderData(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-view" command="view">View</el-dropdown-item>
                  </a>
                  <a @click="editWorkUserData(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-edit-outline" command="edit">Edit</el-dropdown-item>
                  </a>
                  <a @click="onDelete(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-edit-outline" command="edit">Delete</el-dropdown-item>
                  </a>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </data-tables-server>
      </div>
    </div>
    <dialog-component :title="'Add '+ formbuilderDetails.name" :visible="openStartFormbuilderModal" @before-close="CloseStartFormbuilder" :containerWidth="'30%'" class="add-contact-type-popup"
      :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="formbuilder-Add">
        <el-scrollbar wrap-style="max-height: 600px;">
        <p v-if="formbuilderDetails.form_builders_owner == 'USER'">{{'To add '+ formbuilderDetails.name + ', select the company user email'}}</p>
        <p v-else-if="formbuilderDetails.form_builders_owner == 'ENTITY'">{{'To add '+ formbuilderDetails.name + ', select the application user email'}}</p>
        <p v-else>{{'To add '+ formbuilderDetails.name + ', select the receiver email'}}</p>
        <el-select v-model="formbuilderRecievers" placeholder="Select email" filterable style="width: 100%"  v-if="formbuilderDetails.form_builders_owner == 'USER'" 
          clearable multiple collapse-tags>
          <el-option v-for="contact in companyUsers" :key="contact._id" :label="contact.email"
            :value="contact._id"></el-option>
        </el-select>
        <el-select v-model="formbuilderRecievers" placeholder="Select email" filterable style="width: 100%" v-else 
          clearable multiple collapse-tags>
          <el-option v-for="contact in getContacts" :key="contact._id" :label="contact.email"
            :value="contact._id"></el-option>
        </el-select>
      </el-scrollbar> <br>
      <span slot="footer" class="dialog-footer">
           <!-- <el-button type="primary" @click="startFormbuilder" :disabled="!formbuilderRecievers" size="small"  v-if="formbuilderRecievers.length === 1">
             <i class="el-icon-plus"></i> Start
           </el-button> -->
                <el-button type="primary" :disabled="formbuilderRecievers.length === 0"  :icon="!loading ? 'el-icon-s-promotion' : 'el-icon-loading'" @click="sendFormbuilder(formbuilderRecievers)" size="small" 
          v-if=" formbuilderRecievers.length === 1 || formbuilderRecievers.length === 0" > Send Form </el-button>
        <el-button size="small" @click="openStartFormbuilderModal = false">Cancel</el-button>
      </span>
      </div>
    </dialog-component>
    <el-dialog :destroy-on-close="true" :visible.sync="reasonForRejectModal" title="Reason for reject">
      <el-input type="textarea" :rows="2" placeholder="Reason for reject" v-model="reasonForReject"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectFormbuilder(reasonForRejectData)" type="primary"
          :disabled="!reasonForReject.length">Submit</el-button>
        <el-button @click="reasonForRejectModal = false">Cancel</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/config/app";
// import formBuilderSharingSettings from "../widgets/formBuilderSharingSettings.vue";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
export default {
  name: "FormbuilderUsersData",
  components: {
    formbuilderLogs: () =>
      import("@/components/widgets/formbuilderLogsWidget"),
    // formBuilderSharingSettings,
  },
  mixins: [FormbuilderHelper, TemplateBuilderHelper],
  data() {
    return {
      formbuilder_code: null,
      loading: false,
      editDailogVisible: false,
      selectedOfferLetterTemplate: "",
      total: 0,
      currentPage: 1,
      pageSize: 0,
      data: [],
      addDialogVisible: false,
      previewDialogVisible: false,
      selectedOfferLetter: null,
      viewOfferLetterVisible: false,
      documentSelectDialogVisible: false,
      selectedConfigurableDocument: null,
      documentsLoading: false,
      selectedEmployee: null,
      title: "",
      firstTemplateFields: [],
      firstTemplateId: null,
      workflowIconPath: "block-portfolio.svg",
      formbuilderDetails: {},
      workFlowPublicURL: "",
      selectedUserId: "",
      hasIntervals: false,
      intervalType: "",
      fequencyDateMap: {
        DAILY: "YYYY-DD-MM",
        WEEKLY: "YYYY-DD-MM",
        MONTHLY: "YYYY-DD-MM",
        YEARLY: "YYYY-DD-MM",
        HOURLY: "YYYY-DD-MM HH:mm",
      },
      openStartFormbuilderModal: false,
      formbuilderRecievers: [],
      isApprovalFormbuilder: false,
      approver: "",
      reasonForReject: "",
      reasonForRejectModal: false,
      reasonForRejectData: null,
      isApplicationUserSide: false,
      activeWorkspace: "",
      selectedRowsIds: [],
      allEntitiesData: [],
      allCompanyUserTypesData: [],
      companyUsers: [],
    };
  },
  computed: {
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getFormBuilderRemindersSettings",
      "getFormBuilderUpdateStatus",
      "getFormBuilderPublicURLDetails",
    ]),
    ...mapGetters("formbuilderData", [
      "getFormbuilderUsersData",
      "getFormbuilderUniqueUsersData",
      "getFormbuilderCreateByContactData",
      "getFormbuilderCreateByContactStatus",
      "getFormbuilderCreateByContactErrors",
      "getFormbuilderDataDeleteStatus",
      "getFormbuilderDataDeleteError",
      "getFormbuilderDataUpdateStatus",
      "getFormbuilderDataUpdateError",
      "getFormbuilderDataBulkDeleteStatus",
      "getFormbuilderDataBulkDeleteErrors",
      "getSendFormBuilderToUsersStatus",
      "getSendFormBuilderToUsersErrors"
    ]),
    ...mapGetters("templatesData", ["getTemplateFieldsData"]),
    ...mapGetters("companyEntities", ["getSingleCompanyEntity"]),
    ...mapGetters("contacts", ["getAllContacts", "getCheckContactWithEmail"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
      "getCompanyUsersList",
      "getCompanyUsers",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveContactType",
      "getUserTypeList",
    ]),
    ...mapGetters("menuManagementV2", [
      "getEntityMenu",
    ]),
    isCompanyOwner() {
      return this.getFormBuilder.form_builders_owner == "COMPANY";
    },
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    getFormbuilerRoute() {
      return "/fb/" + this.formbuilder_code;
    },
    getFormbuilderTableDataRoute() {
      if(this.isApplicationUserSide){
        return "/ap/fbdv/" + this.formbuilder_code + "/data-list";
      }
      return "/fbd/" + this.formbuilder_code + "/data-list";
    },

    getWorkflowTableBatchesRoute() {
      return "/fbd/" + this.formbuilder_code + "/batches";
    },
    getWorkflowTableAllUsersRoute() {
      return "/fbd/" + this.formbuilder_code + "/all-users";
    },
    logoUploadUrl() {
      return config.S3_BUCKET_URL;
    },
    getUserEmail() {
      return (formbuilderData) => {
        if (formbuilderData.contact) {
          return formbuilderData.contact && formbuilderData.contact.email
            ? formbuilderData.contact.email
            : "";
        }
        return "";
      };
    },
    getUserId() {
      return (workflowData) => {
        if (this.formbuilderDetails.form_builders_owner == "COMPANY") {
          return workflowData.workflow_user_id &&
            workflowData.workflow_user_id._id
            ? workflowData.workflow_user_id._id
            : "";
        } else if (this.formbuilderDetails.form_builders_owner == "ENTITY") {
          return workflowData.entity_data && workflowData.entity_data.email
            ? workflowData.entity_data.entity_data_id
            : "";
        } else {
          return workflowData.user_id && workflowData.user_id._i
            ? workflowData.user_id._id
            : "";
        }
      };
    },
    getFormbuilderSteps() {
      if (this.formbuilderDetails && this.formbuilderDetails.steps) {
        return this.formbuilderDetails.steps.map((step) => {
          if (step.type == "ENTITY") {
            return {
              type: "ENTITY",
              step_id: step._id,
              entity_id:
                step && step.entity && step.entity._id
                  ? step.entity._id
                  : step.entity,
            };
          } else {
            return {
              type: "TEMPLATE",
              step_id: step._id,
              template_id:
                step && step.template && step.template._id
                  ? step.template._id
                  : step.template,
            };
          }
        });
      }
      return [];
    },
    getContacts() {
      return this.getAllContacts && this.getAllContacts.data
        ? this.getAllContacts.data
        : [];
    },
    currentMenu() {
      if (
        this.isApplicationUserSide &&
        (this.$route.params.menuId || this.$route.query.menuId) &&
        this.getEntityMenu?.menu_list
      ) {
        let menu;
        let id = this.$route.params.menuId || this.$route.query.menuId;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            menu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                menu = el;
              } else if (el.children) {
                (el.children || []).forEach((ele) => {
                  if (ele._id == id) {
                    menu = ele;
                  }
                });
              }
            });
          }
        });
        return menu;
      }
      return null;
    }
  },
  async mounted() {
    await this.setRouteConfiguration();
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    [this.allCompanyUserTypesData, this.allEntitiesData] = await Promise.all([
        this.fetchCompanyUsersTypes(),
        this.fetchAllEntities(),
    ]);
    await this.getNecessaryInfo();
    await this.$store.commit(
      "formBuilders/setFormBuilderPrevRouter",
      null,
      {
        root: true,
      }
    );
  },

  methods: {
    //send form builder to selected contacts
    async sendFormbuilder(users){
      let params = {
        selectedUsers: users,
        formbuilder_details_id: this.formbuilderDetails._id,
      };
      this.loading = true;
      await this.$store.dispatch("formbuilderData/sendFormBuilderToUsers", params);
      if (this.getSendFormBuilderToUsersStatus){
          this.$notify.success({
          title: "Success",
          message: "Form builder data is being created, and sending mail has been initiated.",
        });
        this.loading = false;
        this.fetchAllEmployee();
      } else {
        let message = "Error while sending mail";
        if (this.getSendFormBuilderToUsersErrors) {
          message = this.getSendFormBuilderToUsersErrors;
        }
        this.loading= false;
        this.$notify.error({
          title: "Error",
          message: message,
        });
      }
      this.openStartFormbuilderModal = false;
    },
    handleSelectionChange(checkedRows) {
      this.selectedRowsIds = checkedRows;
    },
    async deleteChecked() {
      this.$confirm("Are you sure to delete the form response data?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteSelectedFormData();
      });
    },
    //Bulk delete form response data
    async deleteSelectedFormData(){
      let selectedIds = [];
      this.selectedRowsIds.forEach((item) => {
        if (item && item._id) {
          selectedIds.push(item._id);
        }
      });
      let params = {
        selectedIds: selectedIds,
        formbuilder_details_id: this.getFormBuilder._id,
      };
      this.loading = true;
      await this.$store.dispatch("formbuilderData/formBuilderDataBulkDelete", params);
      if (
        this.getFormbuilderDataBulkDeleteStatus
      ) {
        this.loading= false;
        this.$notify.success({
          title: "Success",
          message: "Form data deleted successfully",
        });
        this.fetchAllEmployee();
      } else {
        let message = "Error while deleting data";
        if (this.getFormbuilderDataBulkDeleteErrors) {
          message = this.getFormbuilderDataBulkDeleteErrors;
        }
        this.loading= false;
        this.$notify.error({
          title: "Error",
          message: message,
        });
      }
    },
    isAnonymousForm(){
      return this.formbuilderDetails?.form_builders_owner === "ANONYMOUS"
    },
    async setRouteConfiguration() {
      if (this.$route?.name == "applicationFormBuilderUserData") {
        if (this.getActiveContactType?.contact_type?._id) {
          this.activeWorkspace = this.getActiveContactType.contact_type._id;
        }
        if (this.$route?.params?.menuId || this.$route?.query?.menuId) {
          this.isApplicationUserSide = true;
          await Promise.all([
            this.$store.dispatch(
              "applicationUsersPermission/fetchPermissionsEntityById",
              { id: this.getActiveContactType.contact_type._id }
            ),
            this.$store.dispatch(
              "menuManagementV2/fetchMenuByEntity",
              this.activeWorkspace
            ),
          ]);
        }
      }
    },
    convertToLocalDateTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleString();
    },
    getApprovedUser(formbuilderData) {
      // In priority based approvals, only main approver details should be displayed
      if (formbuilderData?.approval_users && formbuilderData?.priority_based) {
        return formbuilderData?.approval_users.find(
          (e) => (!formbuilderData.priority_based && e.approved_status) || (formbuilderData.priority_based && e.approved_status && e.final_approver)
        );
      } else {
        // In non-priority based(all users need to approve) approvals, all approvers details should be displayed, so returning all the approvers
        return formbuilderData?.approval_users;
      }
    },
    getRejectedUser(formbuilderData) {
      if (formbuilderData?.approval_users) {
        return formbuilderData?.approval_users.find(
          (e) => (!formbuilderData.priority_based && e.rejected_status) || (formbuilderData.priority_based && e.rejected_status && e.final_approver)
        );
      }
      return null;
    },
    checkIsApproverUser(formbuilderData) {
      if (
        formbuilderData?.approval_users &&
        formbuilderData?.approval_status != "APPROVED" &&
        formbuilderData?.approval_status != "REJECTED"
      ) {
        let currentuserIndex = this.getCurrentApprover(formbuilderData);
        if (
          currentuserIndex > -1 &&
          !formbuilderData.approval_users[currentuserIndex].approved_status &&
          !formbuilderData.approval_users[currentuserIndex].rejected_status
        ) {
          return formbuilderData.formbuilder_data_status == "COMPLETED";
        }
        return false;
      }
      return false;
    },
    async onReject(row) {
      this.$confirm("Are you sure to reject the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.rejectFormbuilder(row);
      });
    },
    showRejectConfirm(formbuilderData) {
      this.reasonForRejectData = null;
      this.reasonForReject = "";
      this.reasonForRejectModal = true;
      this.reasonForRejectData = { ...formbuilderData };
    },
    async rejectFormbuilder(formbuilderData) {
      this.loading = true;
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        let params = this.getRejectParams(formbuilderData, currentuserIndex, this.reasonForReject);
        await this.$store.dispatch("formbuilderData/updateFormbuilderData", params);
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Rejected successfully!",
            type: "success",
          });
          this.reasonForRejectData = null;
          this.reasonForReject = "";
          this.reasonForRejectModal = false;
          this.fetchAllEmployee();
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while approving",
          });
          this.fetchAllEmployee();
        }
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver",
        });
      }
      this.loading = false;
    },
    async onApprove(row) {
      this.$confirm("Are you sure to approve the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.approveFormbuilder(row);
      });
    },
    async approveFormbuilder(formbuilderData) {
      this.loading = true;
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        let params = this.getApprovalParams(formbuilderData, currentuserIndex);
        await this.$store.dispatch("formbuilderData/updateFormbuilderData", params);
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Approved successfully!",
            type: "success",
          });
          this.fetchAllEmployee();
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while approving",
          });
          this.fetchAllEmployee();
        }
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver",
        });
      }
      this.loading = false;
    },
    async startFormbuilder() {
      this.openStartFormbuilderModal = false;
      // Redirecting to preview form to fill the form by owner/sender if there is only one receiver
      let url = config.APP_URL;
      url += `/owner/fb/${this.formbuilderDetails.code}/${this.formbuilderDetails.steps[0].slug}/${this.formbuilderDetails._id}?user_id=${this.getAuthenticatedUser._id}`;
      window.open(url, "_blank");
      let params = this.prepareParams();
      await this.$store.dispatch(
        "formbuilderData/createFormbuilderDataByContact",
        params
      );
      if (this.getFormbuilderCreateByContactStatus) {
        this.$message({
          message: "Form data created successfully!",
          type: "success",
        });
        //this is old form view
        // if (
        //   this.getFormbuilderCreateByContactData &&
        //   this.getFormbuilderCreateByContactData._id
        // ) {
        //   this.$router.push({
        //     name: "FormbuilderEdit",
        //     params: {
        //       formbuilder_code: this.formbuilder_code,
        //       formbuilder_data_id: this.getFormbuilderCreateByContactData._id,
        //     },
        //   });
        // }
      } else {
        this.$notify.error({
          title: "Error",
          message:
            this.getFormbuilderCreateByContactErrors &&
              this.getFormbuilderCreateByContactErrors.critical_error
              ? this.getFormbuilderCreateByContactErrors.critical_error
              : "Something went wrong",
        });
      }
    },
    prepareParams() {
      return {
        formbuilders_details_id: this.formbuilderDetails._id,
        contact: this.formbuilderReciever,
        formbuilder_steps_data: this.getFormbuilderSteps,
      };
    },
    async openStartFormbuilder() {
      if (
        this.isApplicationUserSide &&
        this.formbuilderDetails?.form_builders_owner == "ENTITY" &&
        this.formbuilderDetails?.entity_id == this.activeWorkspace
      ) {
        let url = config.APP_URL;
        url += `/ap/fbd/${this.formbuilderDetails.code}/${this.formbuilderDetails.steps[0].slug}/${this.formbuilderDetails._id}?user_id=${this.getAuthenticatedUser._id}`;
        window.open(url, "_blank");
        return;
      }
      if (
        !this.isApplicationUserSide &&
        this.formbuilderDetails?.form_builders_owner == "USER" &&
        this.formbuilderDetails?.user_type.includes(
          this.getActiveWorkspace.user_type
        )
      ) {
        this.loading = true;
        await this.$store.dispatch("contacts/fetchContactByEmail", {
          email: this.getAuthenticatedUser.email,
          company_id: this.formbuilderDetails.company,
        });
        if (this.getCheckContactWithEmail._id) {
          let url = config.APP_URL;
 
          url += `/public/fbd/${this.formbuilderDetails.code}/${this.formbuilderDetails.steps[0].slug}/${this.formbuilderDetails._id}?user_id=${this.getCheckContactWithEmail._id}`;
 
          window.open(url, "_blank");
          this.loading = false;
          return;
        } else {
          this.loading = false;
        }
      } else {
        this.loading = true;
        let params = {
          get_all: true,
          type: "CONTACT",
        };
        if (
          this.formbuilderDetails?.form_builders_owner == "ENTITY" &&
          this.formbuilderDetails?.entity_id
        ) {
          params = {
            ...params,
            ...{ contact_type: this.formbuilderDetails.entity_id },
          };
        } else if (
          this.formbuilderDetails?.form_builders_owner === "USER" &&
          this.formbuilderDetails?.user_type
        ) {
          await this.$store.dispatch(
            "auth/getCompanyUsersList",
            this.formbuilderDetails.company
          );
          if (this.getCompanyUsers?.data) {
            this.companyUsers = this.getCompanyUsers.data.filter((user) => {
              if (user?.rolesList) {
                let existed = user.rolesList.find(
                  (role) =>
                    role &&
                    role.userType &&
                    this.formbuilderDetails.user_type.includes(
                      role.userType._id || role.userType
                    )
                );
                return existed ? true : false;
              }
            });
          }
        }
        await this.$store.dispatch("contacts/fetchAllContacts", params);
        if (this.getAllContacts?.data || this.getCompanyUsers) {
          this.loading = false;
          this.openStartFormbuilderModal = true;
        }
      }
    },
    printScope(r) {
      console.log(r);
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.selectedUserId = this.$route.params.user_id;

      this.formbuilder_code = this.$route.params.formbuilder_code;

      await this.$store.dispatch("formBuilders/fetchFormBuilderByCode", {
        code: this.formbuilder_code,
        include_template_details: true,
        include_entity_details: true,
        include_documents_details: true,
      });

      this.formbuilderDetails = this.getFormBuilder;

      this.hasIntervals =
        this.getFormBuilder &&
        this.getFormBuilder.settings &&
        this.getFormBuilder.settings.interval_settings &&
        this.getFormBuilder.settings.interval_settings.status;

      this.intervalType =
        this.getFormBuilder &&
          this.getFormBuilder.settings &&
          this.getFormBuilder.settings.interval_settings
          ? this.getFormBuilder.settings.interval_settings.type
          : null;
      this.isApprovalFormbuilder =
        this.formbuilderDetails?.is_approval_form || false;
      let templateIds = this.getFormBuilder.steps
        .filter((step) => step.type == "TEMPLATE")
        .map((e) => e.template._id);

      let entityIds = this.getFormBuilder.steps
        .filter((step) => step.type == "ENTITY")
        .map((e) => e.entity._id);

      this.workflowIconPath =
        this.getFormBuilder.icon_type == "DEFAULT"
          ? this.getFormBuilder.icon || "block-portfolio.svg"
          : "block-portfolio.svg";

      this.firstTemplateId = templateIds ? templateIds[0] : null;
      if (templateIds && templateIds.length) {
        await this.$store.dispatch("templatesData/fetchTemplateFieldsData", {
          template_ids: [this.firstTemplateId],
        });
      }
      if (entityIds && entityIds.length) {
        await this.$store.dispatch(
          "companyEntities/fetchSingleCompanyEntity",
          entityIds[0]
        );
      }
      if (templateIds && templateIds.length) {
        // this.firstTemplateFields = (
        //   this.getTemplateFieldsData[0].active_fields || []
        // ).slice(0, 5);
      }
      // if (this.formbuilderDetails.form_builders_owner == "COMPANY") {
      await this.generateFormbuilderPublicURL();
      // }

      await this.fetchAllEmployee();
    },

    onEdit(row) {
      this.selectedOfferLetterTemplate = row;
      this.editDailogVisible = true;
    },
    async onView(row) {
      this.selectedOfferLetterTemplate = row;
      this.previewDialogVisible = true;
    },
    async onDelete(row) {
      this.$confirm("Are you sure to delete the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteTemplate(row);
      });
    },
    async deleteTemplate(row) {
      //deleteFormbuilderData
      await this.$store.dispatch(
        "formbuilderData/deleteFormbuilderData",
        row._id
      );
      if (this.getFormbuilderDataDeleteStatus) {
        this.$message({
          message: "Response deleted successfully!",
          type: "success",
        });
        this.fetchAllEmployee();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Can't delete.",
        });
      }
    },

    async updateUserQuery(value) {
      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: { ...this.$route.query, user_id: value },
      });

      await this.getNecessaryInfo();
    },
    async fetchAllEmployee() {
      this.loading = true;
      this.data = [];
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        formbuilder_details_id: this.getFormBuilder._id,
        include_template_details: false,
        include_user_details: true,
        user_id: this.$route.query.user_id,
      };
      if (this.isApplicationUserSide && this.currentMenu?.form_builder_type == 'FILLED_BY_ME') {
        params['user_id'] = this.getAuthenticatedUser._id;
      }
      await this.$store.dispatch(
        "formbuilderData/fetchUsersFormbuilderData",
        params
      );
      this.loading = false;
      this.currentPage = parseInt(this.getFormbuilderUsersData.page) || 1;
      this.total = parseInt(this.getFormbuilderUsersData.total) || 0;

      if (
        this.getFormbuilderUsersData.data &&
        this.getFormbuilderUsersData.data.length
      ) {
        this.data = this.getFormbuilderUsersData.data;
      } else {
        this.data = [];
      }
    },
    backToWorkFlows() {
      this.$router.push({ path: "/formBuilders",query:this.$route.query });
    },
    CloseStartFormbuilder(){
      this.openStartFormbuilderModal = false;
    },
    parseFirstTemplateData(data) {
      let modifiedData = data.map((e) => {
        let dataObject = {
          _id: e._id,
          name: e.name,
          code: e.code,
          formbuilder_data_status: e.formbuilder_data_status,
          workflow_data_sender_status: e.workflow_data_sender_status,
        };

        let firstTemplateData = e.template_data_ids.find(
          (x) => x.template_id._id == this.firstTemplateId
        );

        if (firstTemplateData) {
          let firstTemplateValues =
            firstTemplateData.template_data_id &&
              firstTemplateData.template_data_id.template_data
              ? firstTemplateData.template_data_id.template_data
              : {};
          dataObject.template_data = firstTemplateValues;
        } else {
          dataObject.template_data = {};
        }

        return dataObject;
      });
      return modifiedData;
    },
    async fetchAllConfigurableDocuments() {
      this.documentsLoading = true;
      await this.$store.dispatch(
        "configurableDocuments/fetchConfigurableDocuments",
        {
          get_all: true,
          type: "FORM",
        }
      );
      this.documentsLoading = false;
    },
    copyURL(url) {
      navigator.clipboard.writeText(url);
      this.$message({
        message: "Link copied successfully!",
        type: "success",
      });
    },

    async generateFormbuilderPublicURL() {
      try {
        this.loading = true;
        await this.$store.dispatch("formBuilders/fetchWorkFlowPublicURL", {
          formbuilder_details_id: this.getFormBuilder._id,
        });
        if (this.getFormBuilder?.customization?.show_link_to_be_copy) {
          this.workFlowPublicURL =
            this.getFormBuilderPublicURLDetails.public_url;
        }
        this.false = true;
      } catch (err) {
        console.log("generateFormbuilderPublicURL",err);
      }
    },
    async loadData(data) {
      if (this.data && this.data.length) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllEmployee();
      }
    },

    async selectDocuemnt(doc) {
      this.selectedConfigurableDocument = doc;
    }, 
    async viewFormbuilderData(row) {
      if(this.$route.name == 'applicationFormBuilderUserData'){
        await this.$store.commit(
          "formBuilders/setFormBuilderPrevRouter",
          window.location.href,
          {
            root: true,
          }
        );  
        this.$router.push({
          name: "ApplicationUserFormbuilderView",
          params: {
            formbuilder_code: this.formbuilder_code,
            formbuilder_data_id: row._id,
          },
        });
      }else{
        this.$router.push({
          name: "FormbuilderView",
          params: {
            formbuilder_code: this.formbuilder_code,
            formbuilder_data_id: row._id,
          },
        });
      }
    },
    async editWorkUserData(row) {
      if(this.$route.name == 'applicationFormBuilderUserData'){
        await this.$store.commit(
          "formBuilders/setFormBuilderPrevRouter",
          window.location.href,
          {
            root: true,
          }
        );
        this.$router.push({
          name: "ApplicationUserFormbuilderEdit",
          params: {
            formbuilder_code: this.formbuilder_code,
            formbuilder_data_id: row._id,
          },
          query: {
            origin: 'cwdjkrni'
          }
        });
      }else{
        this.$router.push({
          name: "FormbuilderEdit",
          params: {
            formbuilder_code: this.formbuilder_code,
            formbuilder_data_id: row._id,
          },
        });
      }
    },
    getFlowName() {
      return this.getFormBuilder?.name;
    },

    getFieldData(templateData, field) {
      if (templateData[field.key]) {
        if (field.input_type == "DATE") {
          return this.$moment.utc(templateData[field.key]).format("MM-DD-YYYY");
        }
        return templateData[field.key];
      }
      return "-";
    },
  },
  watch: {
    "$route.params.workflow_code": async function () {
      await this.getNecessaryInfo();
    },
    async "$route.query.key"() {
      this.getNecessaryInfo();
    },
    async "$route.params.formbuilder_code"() {
      this.getNecessaryInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.formbuilder-Add{
  margin: 20px;
}
.add-buttons {
  border: 1.5px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0.45em 0.65em;
  color: var(--primary-color);

  &:hover {
    background-color: var(--primary-color);
    color: var(--primary-contrast-color);
    border-color: var(--primary-color);
  }
}

#workflow-users-list {
  width: 90%;
  display: table;
  margin: 0 auto;

  .el-dialog__body {
    padding: 20px 20px;

    .el-card {
      .el-card__body {
        padding: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

.doc {
  min-width: 30px;

  &.selected {
    background: green;
  }
}

.green {
  color: green;
}

.font-17 {
  font-size: 17px;
  cursor: pointer;
}

.documents {
  min-height: 200px;
  max-height: 400px;
  overflow: scroll;
}

.el-table .cell {
  word-break: break-word;
}

.el-table .td {
  word-wrap: break-word;
}
.approver-text{
  color: #3366cc;
}
.list-style{
  margin-top: 10px;
}
</style>
